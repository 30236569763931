/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes rendez-vous';
export const txtnombredoc = 'rendez-vous';
export const txtnombredocpluriel = 'rendez-vous';
export const titleAlveole = 'Mes rendez-vous';



// Local DeV
// export const zUrlApi = "https://localhost:8000"
// export const zAPIRendezvous = "https://localhost:8000/rendezvous";
// export const zUrlBackNotifications = "https://localhost:8001";

export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";
export const zAPIRendezvous = "https://api-mesrendezvous-dev.cristal-hub.fr/rendezvous";
export const zUrlApi ="https://api-mesrendezvous-dev.cristal-hub.fr";

export const TypeRendezvous =[
    { id: 1, title: 'Analyse' },
    { id: 2, title: 'Visite' },
    { id: 3, title: 'Réunion' },
    { id: 4, title: 'Rendez-vous' }
];
// export const Typedocid = [1,2,3,4];
export const PasseAvenir =[
    { id: 5, title: 'Rendez-vous à venir' },
    { id: 6, title: 'Rendez-vous passés' }
];

/**
 * Fin constantes spécifiques alvéole
 */


/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";
